import React from 'react';
import styled from 'styled-components';

import { Link } from 'src/components';

const Wrapper = styled.footer`
  color: var(--dark);
`;

const Text = styled.p`
  margin: 2rem;
  text-align: center;

  a {
    color: inherit !important;
    text-decoration: none;
  }
`;

const year = new Date().getFullYear();

const Footer = () => (
  <Wrapper>
    <Text>
      &copy; {year} Andrew Robert Cernek • <Link to="https://github.com/thisRaptori/raptori">template by raptori</Link>
    </Text>
  </Wrapper>
);

export default Footer;
